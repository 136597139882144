// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
	authentication: {
		issuer: "https://iam.posti.world/realms/posti",
		silentRefreshRedirectUri: "/assets/authentication/silent-refresh.html",
		clientId: "posti-bo",
		responseType: "code",
		scope: "openid profile offline_access roles phone address",
		redirectUri: null,
		postLogoutRedirectUri: null
	},
	services: {
		blockChainService:			"https://api-gateway.posti.world/blockchain-service",
		newBlockchainBasePath:		"https://api-gateway.posti.world/blockchain-service",
		getMediaContentsBasePath:	"https://api-gateway.posti.world/lp-images",

		mediaContentsBasePath:		"https://api-gateway.posti.world/traceability-api/api/v1/bo/images",
		apiBasePath:				"https://api-gateway.posti.world/traceability-api/api/v1/bo",

		delegationUrl:		"https://verifier.posti.world/delegate",
		certDataBaseUrl:	"https://verifier.posti.world/json/{{companyId}}/{{certId}}.json",
		certVerifierUrl:	"https://verifier.posti.world/{companyId}/{certificateId}/verify",
		dataLakeUrl: null,
		apiFreePushPath: "https://api-gateway.posti.world/traceability-api/api/v1/bo/pdp"
	},
	certificationConfig: {

		
	},
	domainConfig: {
		defaultCertPagePatternURL: "https://verifier.posti.world/{companyId}/{id}/verify",
		defaultProductLandingPagePatternURL: "https://www.posti.world/{productId}",
		defaultEntityLandingPagePatternURL: "https://www.posti.world/{productId}?id={id}",
		companyLogo: "assets/img/logos/logo_bicolor_posti.svg",
		appIconUrl: "assets/img/logos/logo_bicolor_posti.svg",
		portalName: "Tracce",
		portalTitle: "Tracce pOsti",
		landingPageSiteLogo: "assets/img/logos/logo_bicolor_posti.svg",
		allowExternalProviders: true,
		emailContact: "info@posti.world",
		showGuideButton: false,
		showSupportButton: false,
		guideButtonUrl: "",
		supportButtonUrl: ""
	},
	certificatePageBaseUrl:	"https://traceability-lp.posti.world/landing",
	landingPageBaseUrl:		"https://traceability-lp.posti.world/landing-v2",
	landingPageHostBaseUrl: "https://traceability-lp.posti.world",
	freeDataProviderBaseUrl: "https://traceability-dp.posti.world",
	startLoginAtStartup: true,
	appRoutes: [
		{
			path: '',
			redirectTo: "user-profile",
			pathMatch: 'full',
		},
		{
			path: 'landing',
		},
		{
			path: '**',
			redirectTo: 'pages/error'
		},
		{
			path: 'unautorized-page',
		}
	]
};
